import React, { useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import queryString from "query-string";
import RootStore from "../stores/index";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const redirectToSpotify = () => {
  const redirUrl =
    process.env.NODE_ENV === "development"
      ? encodeURIComponent("http://localhost:3000/")
      : encodeURIComponent("https://chordplayer.spotify.net");
  window.location.href = `https://accounts.spotify.com/authorize?client_id=6b6babb50c1c409091b98a202a37de19&redirect_uri=${redirUrl}&scope=streaming%20user-read-birthdate%20user-read-email%20user-read-private&response_type=token&state=123`;
};

export const Splash = withRouter(({ history }) => {
  const store = useContext(RootStore);
  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    if (parsedHash.access_token) {
      store.apiStore.token = parsedHash.access_token as string;
      history.push("/player");
    }
  });
  return (
    <div style={{ marginTop: 40 }}>
      <Typography variant="h2">Chord Player</Typography>
      <p>
        <strong>
          You must be on the Spotify network / VPN for this to work
        </strong>
      </p>
      <p style={{ marginTop: 20 }}>
        Chord Player visualizes the chord changes provided by MiQ's music
        intelligence service and plays them via Tone.js.
      </p>
      <p>
        Use it to practice sight reading, to explore MiQ's data, or to generally
        explore song structures.
      </p>
      <Button
        style={{ color: "white", marginTop: 20 }}
        onClick={redirectToSpotify}
        variant="contained"
        color="primary"
      >
        Authorize Chord Player
      </Button>
    </div>
  );
});
