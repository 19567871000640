import { observable, action } from "mobx";
// @ts-ignore
import { Sampler, Midi } from "tone";
import * as pianoNotes from "./piano-wrapper";
export class ChordPlayer {
  @observable
  isPlaying = false;
  @observable
  isReady = false;
  sampler: any;
  constructor() {
    // setup the sampler
    const samplerObj = {};
    this.isReady = false;
    Object.entries(pianoNotes).forEach(([noteName, file]) => {
      // @ts-ignore
      samplerObj[noteName] = file;
    });

    this.sampler = new Sampler(samplerObj).toMaster();
    this.isReady = true;
  }

  @action.bound
  play(chordArr: number[], duration: any) {
    chordArr.forEach(note => {
      this.sampler.triggerAttackRelease(Midi(note).toFrequency(), duration);
    });
  }
}
