import { observable, reaction, computed } from "mobx";
import { RootStoreType } from "./index";
import api from "../utils/api";
// @ts-ignore
import teoria from "teoria";
// @ts-ignore
import { Transport, Time } from "tone";
import throttle from "lodash.throttle";

// the chords sound better up an octave
const transposeUp = 12;

// from colorgorical: http://vrl.cs.brown.edu/color
const colors = [
  "#a6cee3",
  "#1f78b4",
  "#b2df8a",
  "#33a02c",
  "#fb9a99",
  "#e31a1c",
  "#fdbf6f",
  "#ff7f00",
  "#cab2d6",
  "#6a3d9a",
  "#ffff99",
  "#b15928"
];

interface chord {
  confidence: number; // 0 to 1?
  duration: number; // seconds
  time: number; // 0.09287981859410431
  value: string; // "G:maj"
}
interface countAndColor {
  color: string;
  count: number;
}

export interface decoratedChord extends chord {
  tenoChord?: any;
}
export class ApiStore {
  @observable
  token = "";
  rootStore: RootStoreType;
  player: any;

  scheduleIds: any[] = [];

  @observable
  playerUri: string = "";
  uriSub: () => any;

  @observable
  playerPaused = true;

  @observable
  artist = "";

  @observable
  track = "";

  @observable
  songChords: chord[] = [];

  @observable
  loadingAnalysis = false;

  @observable
  analysisErrorMessage = "";

  @observable
  shortestChordDuration: number = 0;

  @observable
  positionSeconds: number = 0;

  setStateThrottled: (arg0: any) => any;

  @computed
  get countOccurences() {
    const map = new Map<string, countAndColor>();
    this.chordsWithMidiNotes.forEach(chord => {
      if (chord.tenoChord === null) {
        return;
      }
      const mapCount = map.get(chord.tenoChord.name);
      if (mapCount === undefined) {
        map.set(chord.tenoChord.name, { color: "", count: 1 });
        return;
      }
      map.set(chord.tenoChord.name, { color: "", count: mapCount.count + 1 });
      return;
    });
    let index = 0;
    map.forEach((countAndColor, key, map) => {
      const targetColor = colors[index % colors.length];
      map.set(key, { ...countAndColor, color: targetColor });
      index++;
    });
    return map;
  }

  constructor(rs: RootStoreType) {
    this.rootStore = rs;
    this.uriSub = reaction(
      () => {
        return this.playerUri;
      },
      () => {
        // TODO find out why I need to wait a second
        setTimeout(() => {
          console.log("stopping from timeout");
          this.stop();
        }, 500);
        const suffix = this.playerUri.split(":")[2];
        this.analysisErrorMessage = "";
        this.loadingAnalysis = true;
        api
          .fetch(
            `/v2/music-intelligence/v1/get/chords/v2/${suffix}.json`,
            "https://yahproxy.spotify.net"
          )
          .then(res => {
            this.loadingAnalysis = false;
            const filtered = this.filterSuperShortChords(
              res.annotations[0].data
            );
            this.shortestChordDuration = this.findShortestChord(filtered);
            this.songChords = filtered;
            this.scheduleIds.forEach(id => {
              Transport.clear(id);
            });
            this.scheduleToneChords();
          })
          .catch(err => {
            this.loadingAnalysis = false;
            this.analysisErrorMessage = err.message;
            debugger;
          });
      }
    );
    this.setStateThrottled = throttle(this.setState, 10);
  }

  setState(state: any) {
    const ms = state.position;
    this.playerPaused = state.paused;
    this.positionSeconds = ms / 1000;
    return this.positionSeconds;
  }

  filterSuperShortChords(chords: chord[]) {
    return chords.filter(chord => chord.duration > 0.2);
  }

  seekToPosition(secs: number) {
    Transport.seconds = secs;
    this.player.seek(secs * 1000);
  }

  play() {
    this.player.resume();
    Transport.start();
  }

  pause() {
    this.player.pause();
    Transport.pause();
  }

  stop() {
    Transport.stop();
    this.player.pause().then(() => this.player.seek(0));
  }

  findShortestChord(chords: chord[]) {
    let currentSmallest = Infinity;
    this.shortestChordDuration = chords.reduce((prevMin, chord) => {
      if (chord.duration < prevMin) {
        return chord.duration;
      }
      return prevMin;
    }, currentSmallest);
    return this.shortestChordDuration;
  }

  scheduleToneChords() {
    const scheduleIdsWithNulls = this.chordsWithMidiNotes.map(chord => {
      if (chord.tenoChord) {
        const notes = chord.tenoChord
          .notes()
          .map((note: any) => note.midi() + transposeUp);
        return Transport.schedule(() => {
          this.rootStore.chordPlayer.play(notes, Time(chord.duration));
        }, Time(chord.time));
      }
      return null;
    });
    this.scheduleIds = scheduleIdsWithNulls.filter(el => el !== null);
  }

  playTrackViaWebAPI(uri: string) {
    return api.putTransfer("/me/player/play", { uris: [uri] });
  }

  treatChordQuality(note: any, qual: string) {
    // since we dont know how to deal with
    //  bmaj/5 right now, lets drop it
    let mutCop = qual;
    if (mutCop.indexOf("/") !== -1) {
      mutCop = mutCop.slice(0, mutCop.indexOf("/"));
    }
    if (mutCop === "hdim7") {
      mutCop = "m7b5";
    }
    // teoria treats maj as major7 but we just want the triad
    if (mutCop === "maj") {
      mutCop = "M";
    }
    return note.chord(mutCop);
  }

  @computed
  get chordsWithMidiNotes(): decoratedChord[] {
    if (this.songChords.length === 0) {
      return [];
    }
    const asWellFormedChords = this.songChords.map((chord: chord) => {
      if (chord.value.split(":").length < 2) {
        return { ...chord, tenoChord: null };
      }
      const split = chord.value.split(":");
      const root = split[0];
      const quality = split[1];
      const note = teoria.note.fromString(root);
      const asTeoriaChord = this.treatChordQuality(note, quality);
      return { ...chord, tenoChord: asTeoriaChord };
    });
    return asWellFormedChords;
  }

  handleFrame = () => {
    if (this.player) {
      this.player.getCurrentState().then((state: any) => {
        if (!state) {
          return;
        }
        this.setStateThrottled(state);
      });
    }

    window.requestAnimationFrame(() => {
      this.handleFrame();
    });
  };

  startStateSync() {
    window.requestAnimationFrame(() => {
      this.handleFrame();
    });
  }
}
