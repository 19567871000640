import { RawStore } from "../stores/index";
// a modified version of
// https://github.com/bnhansn/sling/blob/master/web/src/api/index.js
// which is a simple wrapper around fetch that I like
const API = "https://api.spotify.com/v1";

export function headers(): any {
  return {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
}

export function headersWithToken(): any {
  return {
    ...headers(),
    Authorization: `Bearer ${RawStore.apiStore.token}`
  };
}

export function parseResponse(response: Response): Promise<object> {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response.json();
}

export function queryString(params: any): string {
  const query = Object.keys(params)
    .map(
      (k: string): string =>
        `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    )
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
}
export default {
  fetch(url: string, host?: string, params = {}): Promise<any> {
    const path = host ? `${host}${url}` : `${API}${url}`;
    return fetch(`${path}${queryString(params)}`, {
      method: "GET",
      headers: headersWithToken()
    }).then(parseResponse);
  },

  post(url: string, data: any, host: string): Promise<object> {
    const body = JSON.stringify(data);
    const path = host ? `${host}${url}` : `${API}${url}`;

    return fetch(path, {
      method: "POST",
      headers: headersWithToken(),
      body
    }).then(parseResponse);
  },

  patch(url: string, data: any): Promise<object> {
    const body = JSON.stringify(data);
    return fetch(`${API}${url}`, {
      method: "PATCH",
      headers: headers(),
      body
    }).then(parseResponse);
  },

  putTransfer(url: string, data: any): Promise<object> {
    const body = JSON.stringify(data);
    return fetch(`${API}${url}`, {
      method: "PUT",
      headers: headersWithToken(),
      body
    });
  },

  put(url: string, data: any): Promise<object> {
    const body = JSON.stringify(data);
    return fetch(`${API}${url}`, {
      method: "PUT",
      headers: headersWithToken(),
      body
    }).then(parseResponse);
  },

  delete(url: string): Promise<object> {
    return fetch(`${API}${url}`, {
      method: "DELETE",
      headers: headers()
    }).then(parseResponse);
  }
};
