import React, { useEffect, useContext } from "react";
import RootStore, { RootStoreType } from "../stores/index";
import { ApiStore, decoratedChord } from "../stores/api-store";
import { Redirect } from "react-router-dom";
import api from "../utils/api";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// @ts-ignore
import Loader from "react-loader-spinner";
// @ts-ignore
// @ts-ignore
const minWidth = 50;
const transferPlayback = (store: RootStoreType, dev: string) => {
  return api.putTransfer("/me/player/pause", {}).then(() => {
    return api.putTransfer("/me/player", { device_ids: [dev] }).then(() => {
      // TODO find out why I need to wait a second
      // setTimeout(() => {
      //   store.apiStore.stop();
      // }, 2000);
    });
  });
};

const getBackgroundColor = (store: ApiStore, analysisChord: decoratedChord) => {
  if (analysisChord.tenoChord === null) {
    return "lightgray";
  }
  const countAndColor = store.countOccurences.get(analysisChord.tenoChord.name);
  if (countAndColor === undefined) {
    return "lightgray";
  }
  return countAndColor.color;
};

export const Player: React.FC = observer(() => {
  const store = useContext(RootStore);
  if (!store.apiStore.token) {
    return <Redirect to="/" />;
  }
  useEffect(() => {
    const theScript = document.getElementById("spotId");
    // StartAudioContext(Tone.context, "#start-context");
    if (!theScript) {
      const script = document.createElement("script");
      script.src = "https://sdk.scdn.co/spotify-player.js";
      script.id = "spotId";
      document.body.appendChild(script);
      // @ts-ignore
      window.onSpotifyWebPlaybackSDKReady = () => {
        const token = store.apiStore.token;

        // @ts-ignore
        // eslint-disable-next-line
        const player = new Spotify.Player({
          name: "Chord Player",
          getOAuthToken: (cb: (token: string) => void) => {
            cb(token);
          }
        });
        // Error handling
        player.addListener(
          "initialization_error",
          ({ message }: { message: string }) => {
            console.error(message);
          }
        );
        player.addListener(
          "authentication_error",
          ({ message }: { message: string }) => {
            console.error(message);
          }
        );
        player.addListener(
          "account_error",
          ({ message }: { message: string }) => {
            console.error(message);
          }
        );
        player.addListener(
          "playback_error",
          ({ message }: { message: string }) => {
            console.error(message);
          }
        );

        // Playback status updates
        player.addListener("player_state_changed", (state: any) => {
          if (state && state.track_window && state.track_window.current_track) {
            store.apiStore.playerUri = state.track_window.current_track.uri;
            store.apiStore.artist =
              state.track_window.current_track.artists[0].name;
            store.apiStore.track = state.track_window.current_track.name;
          }
        });

        // Ready
        player.addListener("ready", ({ device_id }: { device_id: string }) => {
          console.log("Ready with Device ID", device_id);
          transferPlayback(store, device_id);
          store.apiStore.startStateSync();
        });

        // Not Ready
        player.addListener(
          "not_ready",
          ({ device_id }: { device_id: string }) => {
            console.log("Device ID has gone offline", device_id);
          }
        );
        // Connect to the player!
        store.apiStore.player = player;
        player.connect();
      };
    }
  });
  const noteItems = store.apiStore.chordsWithMidiNotes.map(
    (decChord, index) => {
      const s = store.apiStore;
      const backgroundColor = getBackgroundColor(s, decChord);
      const width =
        (decChord.duration / store.apiStore.shortestChordDuration) * minWidth;
      const isSelected =
        s.positionSeconds >= decChord.time &&
        s.positionSeconds < decChord.time + decChord.duration;
      return (
        <div
          key={index}
          onClick={() => {
            store.apiStore.seekToPosition(decChord.time);
          }}
          style={{
            height: 50,
            margin: "10px 0px",
            width: width,
            backgroundColor: backgroundColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: isSelected ? 2 : 1,
            outline: isSelected ? "5px solid red" : "1px solid gray"
          }}
        >
          <span style={{ fontWeight: "bold" }}>{decChord.value}</span>
        </div>
      );
    }
  );
  return (
    <>
      <div>
        <Typography variant="h2" style={{ marginBottom: 20, marginTop: 40 }}>
          Chord Player
        </Typography>

        <p>Pick a track in the client or enter a track uri:</p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              console.log("stopping from form");
              store.apiStore.stop();
              store.apiStore.playTrackViaWebAPI(store.uiFormStore.spotUriInput);
            }}
          >
            <input
              type="text"
              placeholder="spotify:track:foo"
              value={store.uiFormStore.spotUriInput}
              onChange={e => {
                const val = e.target.value;
                store.uiFormStore.spotUriInput = val;
              }}
            />
            <Button
              style={{ color: "white", marginLeft: 5 }}
              id="start-context"
              type="submit"
              variant="contained"
              color="primary"
            >
              Load Track
            </Button>
          </form>
          {store.apiStore.loadingAnalysis && (
            <div style={{ marginTop: 10 }}>
              <Loader type="Bars" color="#1DB954" height="20" width="20" />
              <span>Loading chords...</span>
            </div>
          )}
          <div style={{ color: "red" }}>
            {store.apiStore.analysisErrorMessage}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: 300
            }}
          >
            <Button
              style={{ color: "white" }}
              onClick={() => {
                store.apiStore.stop();
              }}
              variant="contained"
              color="primary"
            >
              Stop
            </Button>
            {store.apiStore.playerPaused ? (
              <Button
                style={{ color: "white" }}
                onClick={() => {
                  store.apiStore.play();
                }}
                variant="contained"
                color="primary"
              >
                Play
              </Button>
            ) : (
              <Button
                style={{ color: "white" }}
                onClick={() => {
                  store.apiStore.pause();
                }}
                variant="contained"
                color="primary"
              >
                Pause
              </Button>
            )}
          </div>
        </div>
        {store.apiStore.playerUri && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <p>
              <strong>Artist: </strong>
              {store.apiStore.artist}
            </p>
            <p style={{ marginLeft: 10 }}>
              <strong>Track: </strong>
              {store.apiStore.track}
            </p>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "80%"
            }}
          >
            {noteItems}
          </div>
        </div>
      </div>
    </>
  );
});
