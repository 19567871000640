import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Player } from "./routes/player";
import { Splash } from "./routes/splash";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <div>
            <Route path="/" exact component={Splash} />
            <Route path="/player" exact component={Player} />
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
