import { createContext } from "react";
import { ApiStore } from "./api-store";
import { ChordPlayer } from "./chord-player";
import { UIFormStore } from "./ui-form-store";
import { ToneMasterStore } from "./tone-master-store";
export class RootStoreType {
  apiStore: ApiStore;
  chordPlayer: ChordPlayer;
  uiFormStore: UIFormStore;
  toneMasterStore: ToneMasterStore;
  constructor() {
    this.apiStore = new ApiStore(this);
    this.chordPlayer = new ChordPlayer();
    this.uiFormStore = new UIFormStore();
    this.toneMasterStore = new ToneMasterStore();
  }
}
export const RawStore = new RootStoreType();
export default createContext(RawStore);
