import { createMuiTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1DB954"
    },
    secondary: orange
  }
});
